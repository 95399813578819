<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs5 md5>
        <v-autocomplete
          v-model="model.campaignType"
          label="Campaign Type"
          :counter="max"
          :items="campaignType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        
      </v-flex>
      <v-flex xs5 md5 title="Left Time">
          <v-text-field v-model="model.day" label="Day" hide-details></v-text-field>
          <v-text-field v-model="model.hours" label="Hours" hide-details></v-text-field>
          <v-text-field v-model="model.minute" label="Minute" hide-details></v-text-field>
      </v-flex>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import campaignType from "@/lib/campaignType";
import router from "@/router";
export default {
  data: () => ({
    model: {
      campaignType:-1,
      day:0,
      hours:0,
      minute:0
    },
    campaignType: campaignType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
  }),

  methods: {
    async save() {
        await this.$store.dispatch("campaigns/post", {c:this.model.campaignType,l:(86400*this.model.day)+(3600)*this.model.hours+(60*this.model.minute)});
      router.push("/campaign");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
};
</script>

export default [
    { value: "Any", key: -999 },
    { value: "Multi", key: 0 },
    { value: "ComboChest", key: 1 },
    { value: "Lottery", key: 2 },
    { value: "OneBuyOneFree", key: 3 },
    { value: "BingoBall", key: 4 },
   // { value: "FirstTimePurchase", key: 5 },
    { value: "AllSlotUnLock" , key:6},
    { value: "WantMoreCoins" , key:7},
    { value: "DailyMission" , key:8}
]